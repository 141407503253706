import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedin,
  faTwitterSquare,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import Img from "gatsby-image"

const Bio = ({ author }) => {
  return (
    <div className="bio">
      <Img
        fixed={author.avatar.childImageSharp.fixed}
        alt={author.name}
        className="author-avatar"
      />
      <div>
        <p>
          <b>{author.name}</b>
        </p>
        <p>{author.bio}</p>
        {author.linkedin && (
          <div className="social-box">
            <FontAwesomeIcon icon={faLinkedin} />
            <a
              className="social-link"
              href={`https://www.linkedin.com/in/${author.linkedin}`}
              target="_blank"
              rel="noreferrer"
            >
              {author.linkedin}
            </a>
          </div>
        )}
        {author.twitter && (
          <div className="social-box">
            <FontAwesomeIcon icon={faTwitterSquare} />
            <a
              className="social-link"
              href={`https://twitter.com/${author.twitter}`}
              target="_blank"
              rel="noreferrer"
            >
              {author.twitter}
            </a>
          </div>
        )}
        {author.github && (
          <div className="social-box">
            <FontAwesomeIcon icon={faGithub} />
            <a
              className="social-link"
              href={`https://github.com/${author.github}`}
              target="_blank"
              rel="noreferrer"
            >
              {author.github}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default Bio
